<template>
    <v-row class="app-invoice-preview">
        <v-col cols="12" md="9">
            <v-container class="main_print">
                <h1>فاتورة حساب</h1>
                <h3>اكاديمية البشير لتكنولوجيا المعلومات والذكاء الاصطناعي</h3>
                <v-row>
                    <v-col cols="6">
                        <table>
                            <tr>
                                <th>الاسم</th>
                                <td>{{ data.customer_name }}</td>
                            </tr>
                            <tr>
                                <th>تاريخ الفاتورة</th>
                                <td>{{ currentDate }}</td>
                            </tr>
                        </table>
                    </v-col>
                    <v-col cols="6">
                        <table class="float-right">
                            <tr>
                                <th>الدورة</th>
                                <td>{{ data.courses_created_details.course }}</td>
                            </tr>
                            <tr>
                                <th>المبلغ</th>
                                <td>{{ numberWithComma(data.courses_created_details.courses_price) }}</td>
                            </tr>
                        </table>
                    </v-col>

                </v-row>

                <v-row class="payment_table_row">
                    <v-col cols="12">
                        <table class="payment_table">
                            <tr>
                                <th>مبلغ التسديد</th>
                                <th>تاريخ التسديد</th>
                            </tr>
                            <tr v-for="(item, index) in data.payment" :key="index">
                                <td>{{ numberWithComma(item.money) }}</td>
                                <td>{{ item.date.slice(0, 10) }}</td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="6">
                        <table>
                            <tr>
                                <th>المجموع</th>
                                <td>{{ numberWithComma(data.courses_created_details.courses_price) }}</td>
                            </tr>
                            <tr>
                                <th>التسديدات</th>
                                <td>{{ numberWithComma(data.total_payment) }}</td>
                            </tr>
                            <tr>
                                <th>الخصم</th>
                                <td>{{ numberWithComma(data.discount) }}</td>
                            </tr>
                            <tr>
                                <th>المتبقي</th>
                                <td>{{ numberWithComma(data.remaining_money) }}</td>
                            </tr>
                        </table>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12">
                        <div class="footer">
                            <footer>
                                <p>{{ results.account_name }}</p>
                                <p>{{ "/ " + "&nbsp;" + "&nbsp;" + " /" + currentYear }}</p>
                            </footer>
                        </div>
                    </v-col>
                </v-row>


            </v-container>


        </v-col>
        <v-col cols="12" md="3" class="removed">
            <v-card>
                <v-card-text>
                    <v-btn class="mb-3" color="secondary" block outlined @click="printInvoice">
                        الطباعة
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>


<script>
import { getCurrentDateInString } from '@/constants/date'
import numberWithComma from '@/constants/number'


export default {

    data() {
        return {
            currentDate: getCurrentDateInString(),
            data: {
                customer_name: null,
                payment: [],
                courses_created_details: {
                    course: null,
                    courses_price: null,
                },

                remaining_money: null,
                total_payment: null,
                discount: null,

            },

            results: {
                account_name: null,
            },

            currentYear: null,

            value: 'https://example.com',
            size: 100,
        }
    },

    mounted() {
        this.data = JSON.parse(localStorage.getItem('print'))
        this.results = JSON.parse(localStorage.getItem('results'))

        const currentDate = new Date();
        this.currentYear = currentDate.getFullYear();
    },

    methods: {
        printInvoice() {
            window.print();
        },

        numberWithComma,
    }
}
</script>


<style lang="scss" scoped>
.app-invoice-preview {
    height: 100%;
    margin: 0;
}


.v-card {
    border: none;
    border-radius: 0;
}

.main_print {
    width: 100%;
    padding-right: 50px;
    padding-left: 50px;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;

    h1 {
        margin-top: 25px;
        text-align: center;
        font-size: 15px;
        background-color: black;
        color: white;
        padding: 3px 0;
        border-radius: 0.25em
    }

    h3 {
        text-align: center;
        font-size: 15px;
        margin-top: 25px;
    }

    table {
        border-collapse: collapse;
        margin-top: 25px;
        font-size: 11px;
        font-weight: bold;
        border-spacing: 5px;

        th,
        td {
            border: 1px solid #ddd;
            padding: 5px;
            text-align: right;
            border-bottom: 1px solid #ddd;
            text-align: center;

        }

        th {
            width: 50%;
            background-color: #662e91;
            color: white;
        }
    }
}

.main_print::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background-image: url('../../assets/alBashir_logo.svg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    background-position: center;
    opacity: 0.1;
}


.payment_table_row {
    display: flex;
    justify-items: center;
}

.payment_table {
    width: 100%;
    margin-top: 25px;

    th,
    td {
        border: 1px solid #ddd;
        padding: 5px;
        text-align: right;
        border-bottom: 1px solid #ddd;
    }

    th {
        width: 50%;
        background-color: #ddd;
    }
}

.show_info {
    width: 70%;
}

.footer {
    position: static;
    margin-top: 50px;
    // width: 100%;
    height: 60px;
    display: flex;
    justify-content: left;
    text-align: center;
}



@media print {
    .v-application {
        background: none !important;
    }

    .app-invoice-preview .col-12:first-child .v-card {
        height: 100%;
    }

    .v-sheet.v-card {
        border: none;
    }

    .app-navigation-menu,
    .v-app-bar,
    .v-footer,
    .product-buy-now,
    .app-invoice-preview.row .removed {
        display: none !important;
    }

    .app-invoice-preview.row .col-12:first-child {
        max-width: 100% !important;
        flex: 1 !important;
    }
}
</style>